













































import { Component, Vue } from "vue-property-decorator";
import TopBar from "@/components/TopBar.vue";

@Component({
  components: {
    TopBar,
  },
})
export default class Helps extends Vue {
  public mounted() {
    window["analytics"]["page"]("/help");
  }
}
